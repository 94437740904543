<template>
  <v-container grid-list-xl>
    <h2>Edit Your Profile</h2>
    <v-row>
      <v-col cols="12">
        <v-container fluid class="pt-12">
          <v-row class="pt-12">
            <v-col cols="12" md="5">
              <v-text-field
                name="name"
                label="Email"
                v-model="email"
                disabled
              ></v-text-field>
              <v-text-field
                name="name"
                label="Full Name"
                v-model="name"
                :value="getUser.name"
              ></v-text-field>
              <v-text-field
                name="Address"
                label="Address"
                v-model="address"
                :value="getUser.address"
              ></v-text-field>
              <div v-if="isUpdated" class="green--text">
                Your Profile has been updated!
              </div>
              <v-btn color="primary" @click="updateProfile">Save</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      email: "",
      address: "",
      name: "",
      isUpdated: false,
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  watch: {
    getUser: {
      handler(val) {
        if (val) {
          var u = this.getUser;
          this.name = u.name;
          this.address = u.address;
          this.email = u.email;
        }
      },
      immediate: true,
    },
  },
  methods: {
    updateProfile() {
      this.$store
        .dispatch("updateProfile", { name: this.name, address: this.address })
        .then((res) => {
          this.isUpdated = true;
        });
    },
  },
};
</script>

<style>
</style>